var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DashboardCard", {
        staticClass: "admin-card p-0 m-0",
        attrs: { color: _vm.$theme.backColor },
        on: { click: _vm.onClick },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _c(
                  "span",
                  [
                    _c("CIcon", { attrs: { name: "cil-home" } }),
                    _vm._v(" " + _vm._s(_vm.name) + " ")
                  ],
                  1
                ),
                _c("span", { staticClass: "float-right" }, [
                  _vm._v(" ID: " + _vm._s(_vm.branchId) + " ")
                ])
              ]
            },
            proxy: true
          },
          {
            key: "body",
            fn: function() {
              return [
                _c(
                  "CCardBody",
                  {
                    staticClass: "overflow-none m-0 p-0",
                    on: { click: _vm.onClick }
                  },
                  [
                    _c(
                      "CRow",
                      [
                        _c("CCol", { staticClass: "ml-1 p-0" }, [
                          _c("h4", [_vm._v(_vm._s(_vm.description))])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "CRow",
                      [
                        _vm.map
                          ? _c(
                              "CCol",
                              { staticClass: "p-0" },
                              [
                                _c("SimpleLeafletMap", {
                                  ref: "map",
                                  staticClass: "h-100",
                                  staticStyle: { "min-height": "250px" },
                                  attrs: {
                                    center: _vm.center,
                                    marker: _vm.center,
                                    defaultZoom: 14
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "CRow",
                      [
                        _c("CCol", { staticClass: "p-1" }, [
                          _c("div", { staticClass: "p-0 m-0" }, [
                            _c(
                              "span",
                              { staticClass: "float-left" },
                              [
                                _c("CAvatar", {
                                  attrs: {
                                    initials: _vm.getBranchID(_vm.branchInfo),
                                    username: _vm.branchInfo.name
                                  }
                                })
                              ],
                              1
                            ),
                            _c("span", { staticClass: "float-left" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "p-1",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "card-text card-text-light data-heading"
                                    },
                                    [_vm._v("Address")]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "card-text-dark asset-detail",
                                      attrs: { id: "address" }
                                    },
                                    [
                                      _vm._v(" " + _vm._s(_vm.address) + " "),
                                      _c("br")
                                    ]
                                  )
                                ]
                              )
                            ])
                          ])
                        ])
                      ],
                      1
                    ),
                    _c("CRow")
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }