<template>
  <div>
    <DashboardCard
      class="admin-card p-0 m-0"
      :color="$theme.backColor"
      @click="onClick"
    >
      <template #header>
        <span> <CIcon name="cil-home" /> {{ name }} </span>
        <span class="float-right"> ID: {{ branchId }} </span>
      </template>
      <template #body>
        <CCardBody class="overflow-none m-0 p-0" @click="onClick">
          <CRow >
            <CCol class="ml-1 p-0">
              <h4>{{description}}</h4>
            </CCol>
          </CRow>
          <CRow >
            <CCol class="p-0" v-if="map">
              <SimpleLeafletMap
                ref="map"
                style="min-height: 250px"
                class="h-100"
                :center="center"
                :marker="center"
                :defaultZoom="14"
              >
              </SimpleLeafletMap>
            </CCol>
          </CRow>
          <CRow>
           
            <CCol class="p-1">
              <div class="p-0 m-0">
              <span class="float-left">
                <CAvatar
                        :initials="getBranchID(branchInfo)"
                        :username="branchInfo.name"
                      />
                
              </span>
              <span class="float-left">
              <div style="width: 100%" class="p-1">
                <p class="card-text card-text-light data-heading">Address</p>
                <p id="address" class="card-text-dark asset-detail">
                  {{ address }} <br />
                </p>
              </div>
              </span>
              </div>
            </CCol>
          </CRow>
          <CRow> </CRow>
        </CCardBody>
      </template>
    </DashboardCard>
  </div>
</template>



<script>
/** Displays Branch Info in a Simple Card Component  */

import SimpleLeafletMap from "@/components/dashboard/SimpleLeafletMap";
import CGeocode from "@/components/base/CGeocode";
import DashboardCard from "@/components/dashboard/DashboardCard";
import CAvatar from "@/components/base/CAvatar"; 
export default {
  name: "BranchCard",
  components: {
    SimpleLeafletMap,
    CGeocode,
    DashboardCard,
    CAvatar, 
  },
  props: {
    branchInfo: {
      type: Object,
    },
    horizontal: {
      type: Boolean, 
      default: false, 
    },
    map: {
      type: Boolean,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    name() {
      return this.branchInfo ? this.branchInfo.name : "";
    },
    description() {
      return this.branchInfo ? this.branchInfo.description : "";
    },
    address() {
      return this.branchInfo ? this.branchInfo.address : "";
    },
    center() {
      let temp = this.branchInfo ? this.branchInfo.geolocation : undefined;
      if (!temp) {
        return undefined;
      }

      return [parseFloat(temp.lat), parseFloat(temp.lng)];
    },
    branchId() {
      return this.branchInfo ? this.branchInfo.branch_id : undefined;
    },
    imageSrc() {
     return this.getBranchImg(this.branchInfo);
    },
  },
  methods: {
    onClick(e) {
      this.$emit("click", this);
    },
    getBranchID(branchInfo){
      if(!branchInfo)
        return ""; 
      let id = branchInfo.metadata? branchInfo.metadata.code : "";
      return id; 
    },
    getBranchImg(branchInfo) {
      let id;
      try {
        id = this.getBranchID(branchInfo); 
        id = id.toLowerCase();
        if (!id) {
          return "";
        }
      } catch (err) {
        console.log(err);
        return "";
      }
      return `https://i2.wp.com/cdn.auth0.com/avatars/${id}.png?ssl=1`;
    },
  },
};
</script>

<style scoped>
</style>
